import React from 'react';

import { ClientUiModel, WorkerUiModel } from '../ui-model/user-management/ContactUiModel';
import { WorkerData, ClientData } from '../../domain/user-management/ContactData';
import {
  UpdateClient,
  SendWorkerInvitation,
  UpdateWorker,
} from '../../domain/user-management/Contact';
import { UserRoles, getRoleName, getRoles } from '../../domain/user-management/UserRoles';
import {
  ContactsRepository,
  CompaniesRepository,
} from '../../domain/user-management/UsersRepository';
import {
  getContactsUseCase,
  getWorkersUseCase,
  getClientsUseCase,
} from '../../useCases/user-management/getContactsUseCase';
import {
  getCompaniesUseCase,
  getMyCompanyDataUseCase,
} from '../../useCases/user-management/getCompaniesUseCase';
import {
  updateClientUseCase,
  updateWorkerUseCase,
  sendWorkerInvitationUseCase,
} from '../../useCases/user-management/updateContactUseCase';
import { newCompanyUseCase } from '../../useCases/user-management/newCompanyUseCase';
import {
  updateCompanyUseCase,
  updateMyCompanyUseCase,
} from '../../useCases/user-management/updateCompanyUseCase';
import { deleteContactUseCase } from '../../useCases/user-management/deleteContactUseCase';
import { deleteCompanyUseCase } from '../../useCases/user-management/deleteCompanyUseCase';
import { NewCompany } from '../../domain/user-management/NewCompany';
import { getCountiesValueWithId } from '../../useCases/getCountiesUseCase';
import { CountiesRepository } from '../../domain/county/CountyRepository';
import { AuthRepository } from '../../domain/authenticationFlow/AuthRepository';

function userManagementViewModel(
  contactRepo: ContactsRepository,
  companiesRepo: CompaniesRepository,
  authRepo: AuthRepository,
  countiesRepo: CountiesRepository,
) {
  const getContacts = React.useCallback(
    function () {
      getContactsUseCase({
        getContacts: contactRepo.getContacts,
        contacts: contactRepo.contacts,
      });
    },
    [contactRepo.getContacts],
  );

  const getWorkersUiModel = function () {
    return getWorkersUiModels(getWorkersUseCase(contactRepo));
  };

  const getClientsUiModel = function () {
    return getClientsUiModels(getClientsUseCase(contactRepo));
  };

  const newContact = React.useCallback(
    function (contact: UpdateClient) {
      updateClientUseCase(contactRepo, contact);
    },
    [contactRepo.updateClient],
  );

  const updateWorker = React.useCallback(
    function (worker: UpdateWorker) {
      updateWorkerUseCase(contactRepo, worker);
    },
    [contactRepo.updateWorker],
  );

  const sendWorkerInvitation = React.useCallback(
    function (invitationData: SendWorkerInvitation) {
      sendWorkerInvitationUseCase(contactRepo, invitationData);
    },
    [contactRepo.sendWorkerInvitation],
  );

  const deleteContact = React.useCallback(
    function (id: number) {
      deleteContactUseCase(contactRepo, id);
    },
    [contactRepo.deleteContact],
  );

  const getCompanies = React.useCallback(
    function () {
      getCompaniesUseCase({
        getCompanies: companiesRepo.getCompanies,
        companies: companiesRepo.companies,
        getMyCompanyData: companiesRepo.getMyCompanyData,
      });
    },
    [companiesRepo.getCompanies],
  );

  const getMyCompanyData = React.useCallback(
    function () {
      return getMyCompanyDataUseCase({
        getCompanies: companiesRepo.getCompanies,
        companies: companiesRepo.companies,
        getMyCompanyData: companiesRepo.getMyCompanyData,
      }).then((company) => {
        if (company !== null) {
          return {
            id: company.id,
            name: company.name !== null && company.name !== null ? company.name : '',
            streetName:
              company.streetName !== null && company.streetName !== null ? company.streetName : '',
            cityName:
              company.cityName !== null && company.cityName !== null ? company.cityName : '',
            countrySubentity:
              company.countrySubentity !== null && company.countrySubentity !== null
                ? company.countrySubentity
                : '',
            identificationCode:
              company.identificationCode !== null && company.identificationCode !== null
                ? company.identificationCode
                : '',
            workPlaceAddress:
              company.workPlaceAddress && company.workPlaceAddress !== null
                ? company.workPlaceAddress
                : '',
            capitalSocial: company.capitalSocial,
            cui: company.cui && company.cui !== null ? company.cui : '',
            nrRegCom:
              company.nrRegCom !== null && company.nrRegCom !== null ? company.nrRegCom : '',
            vat: company.vat !== null && company.vat !== null ? company.vat : '',
            bank: company.bank !== null && company.bank !== null ? company.bank : '',
            codIban: company.codIban !== null && company.codIban !== null ? company.codIban : '',
            laborColor: company.color,
            einvoiceToken:
              company.einvoiceToken !== null && company.einvoiceToken !== null
                ? company.einvoiceToken
                : '',
            invoicePrefix:
              company.invoicePrefix !== null && company.invoicePrefix !== null
                ? company.invoicePrefix
                : '',
          };
        } else {
          return null;
        }
      });
    },
    [companiesRepo.getMyCompanyData],
  );

  const getCompaniesUiModel = function () {
    if (companiesRepo.companies === undefined || companiesRepo.companies === null) return [];
    return companiesRepo.companies?.map((company) => {
      const coll = company.collaborators?.map((coll) => {
        return { id: coll.id, name: coll.name };
      });
      return {
        id: company.id,
        name: company.name,
        streetName:
          company.streetName !== null && company.streetName !== null ? company.streetName : '',
        cityName: company.cityName !== null && company.cityName !== null ? company.cityName : '',
        countrySubentity:
          company.countrySubentity !== null && company.countrySubentity !== null
            ? company.countrySubentity
            : '',
        identificationCode:
          company.identificationCode !== null && company.identificationCode !== null
            ? company.identificationCode
            : '',
        collaborators: coll,
        isContact: false,
        workstation: company.workPlaceAddress,
        cui: company.cui,
        nrRegCom: company.nrRegCom,
        bank: company.bank,
        codIban: company.codIban,
      };
    });
  };

  const newCompany = React.useCallback(
    function (company: NewCompany) {
      newCompanyUseCase(companiesRepo, company);
    },
    [companiesRepo.newCompany],
  );

  const updateCompany = React.useCallback(
    function (company: NewCompany) {
      updateCompanyUseCase(companiesRepo, company);
    },
    [companiesRepo.newCompany],
  );

  const updateMyCompanyData = React.useCallback(
    function (company) {
      return updateMyCompanyUseCase(companiesRepo, company);
    },
    [companiesRepo.newCompany],
  );

  const deleteCompany = React.useCallback(
    function (id: number) {
      deleteCompanyUseCase(companiesRepo, id);
    },
    [companiesRepo.deleteCompany],
  );

  const getUsersRoles = function () {
    return getRoles().filter((role) => role.id !== UserRoles.LABOR_OWNER);
  };

  const getCountiesWithId = function () {
    return getCountiesValueWithId(countiesRepo);
  };

  // PRIVATE FUNCTIONS

  function getWorkersUiModels(list: WorkerData[]): Array<WorkerUiModel> {
    const newList: Array<WorkerUiModel> = [];
    list.forEach((contact) => {
      const user = contact.user;
      if (contact.id !== null) {
        newList.push({
          id: contact.id,
          avatar: contact.name === null ? '' : contact.name,
          name: contact.name === null ? '' : contact.name,
          email: contact.email,
          phone: contact.phoneNumber,
          roleName: user !== null ? getRoleName(user.role < 0 ? -1 * user.role : user.role) : null,
          roleId: user !== null ? user.role : null,
          isContact: true,
          profileColor:
            user !== null && user.profileColor !== null
              ? user.profileColor
              : 'var(--dts_default_blue)',
        });
      }
    });
    if (newList === null) {
      return [];
    }
    return newList;
  }

  function getClientsUiModels(list: ClientData[]): ClientUiModel[] {
    const newList: Array<ClientUiModel> = [];

    list?.forEach((contact) => {
      const companiesName: Array<string> = [];
      companiesRepo.companies
        ?.filter(
          (c) => contact.companies !== null && contact.companies.some((item) => c.id === item),
        )
        .map((company) => {
          companiesName.push(company.name);
        });
      newList.push({
        id: contact.id,
        avatar: contact.name,
        name: contact.name,
        email: contact.email,
        phone: contact.phoneNumber,
        companies: contact.companies,
        isContact: true,
        companiesName: companiesName,
      });
    });

    if (newList === null) {
      return [];
    }
    return newList;
  }

  return {
    userId: authRepo.userId,
    contacts: contactRepo.contacts,
    isLoadingContacts: contactRepo.isLoading,
    isUpdatingContacts: contactRepo.isUpdating,
    getCountiesWithId,
    getContacts,
    getWorkersUiModel,
    getClientsUiModel,
    getUsersRoles,
    newContact,
    updateWorker,
    sendWorkerInvitation,
    deleteContact,

    companies: companiesRepo.companies,
    isLoadingCompanies: companiesRepo.isLoading,
    isUpdatingCompanies: companiesRepo.isUpdating,
    getCompanies,
    getMyCompanyData,
    getCompaniesUiModel,
    newCompany,
    updateCompany,
    updateMyCompanyData,
    deleteCompany,
  };
}

export { userManagementViewModel };
