import React, { FC, useRef, useState } from 'react';
import styled from 'styled-components';
import Select, {
  components,
  CSSObjectWithLabel,
  DropdownIndicatorProps,
  MultiValue,
  MultiValueRemoveProps,
  OnChangeValue,
} from 'react-select';

import { CLIENT, WORKER } from '../../domain/user-management/UserStatus';
import { BaseDialog } from '../components/generic/BaseDialog';
import { Text, TextType } from '../components/dsm/Text';
import { Button } from '../components/dsm/buttons/Button';
import { ReactComponent as HeadIcon } from '../../icons/headIcon.svg';
import { ReactComponent as DropdownIcon } from '../../icons/arrowDown.svg';
import Avatar from '../components/generic/Avatar';
import { Checkbox } from '../components/dsm/Checkbox';
import { LANGUAGE_KEYS } from '../translations/languageKeys';
import useTranslate from '../translations/useTranslate';
import { UserRoles } from '../../domain/user-management/UserRoles';
import { emailPattern } from '../components/dsm/InputPatterns';
import DynamicDropdown from '../components/generic/DynamicDropdown';
import { ReactComponent as PlusIcon } from '../../icons/plus.svg';
import { ReactComponent as CloseIcon } from '../../icons/close.svg';

export type AddContactDialogProps = {
  modalName: string;
  itemModel: any;
  isWorker: boolean;
  editInvite: boolean;
  roles: { id: number; name: string }[];
  companies: { value: number; label: string }[];
  onAddContact: (newContact) => void;
  onSendInviteEmail: (data) => void;
  onClose: () => void;
};

const DropdownIndicator: FC<DropdownIndicatorProps> = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <PlusIcon />
    </components.DropdownIndicator>
  );
};

const MultiValueRemove: FC<MultiValueRemoveProps> = (props) => {
  return (
    <components.MultiValueRemove {...props}>
      <CloseIcon />
    </components.MultiValueRemove>
  );
};
const NoOptionsMessage = (props) => {
  const translate = useTranslate();
  return (
    <components.NoOptionsMessage {...props}>
      <Text type={TextType.CAPTION_BOLD}>{translate(LANGUAGE_KEYS.NO_MORE_OPTION)}</Text>
    </components.NoOptionsMessage>
  );
};

const AddContactDialog = (props: AddContactDialogProps) => {
  const translate = useTranslate();
  const {
    modalName,
    itemModel,
    isWorker,
    editInvite,
    roles,
    companies,
    onAddContact,
    onSendInviteEmail,
    onClose,
  } = props;

  const [name, setName] = useState(
    itemModel !== null && itemModel.name !== null && itemModel.name !== null ? itemModel.name : '',
  );
  const [email, setEmail] = useState(
    itemModel !== null && itemModel.email !== null && itemModel.email !== null
      ? itemModel.email
      : '',
  );
  const [phoneNumber, setPhoneNumber] = useState(
    itemModel !== null && itemModel.phone !== null && itemModel.phone !== null
      ? itemModel.phone
      : '',
  );
  const [selectedRoleId, setSelectedRoleId] = useState<number>(
    itemModel !== null && itemModel.roleId !== null && itemModel.roleId !== null
      ? itemModel.roleId < 0
        ? -1 * itemModel.roleId
        : itemModel.roleId
      : roles[0].id,
  );

  const [sendInviteCheckbox, setSendInviteCheckbox] = useState<boolean>(editInvite);
  const [openSelect, setOpenSelect] = useState<boolean>(false);

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedDropdownValue, setSelectedDropdownValue] = useState<string>('');

  const roleSelectRef = useRef<HTMLDivElement | null>(null);
  const modalRef = useRef<HTMLDivElement | null>(null);
  const controlRef = useRef<HTMLDivElement>(null);

  const selectedComapnyList: Array<{ value: number; label: string }> = [];

  if (
    itemModel !== null &&
    itemModel.companies !== null &&
    itemModel.companies !== null &&
    itemModel !== undefined &&
    itemModel.companies !== undefined &&
    itemModel.companies !== undefined
  ) {
    companies.forEach((comp) => {
      if (itemModel.companies.some((c) => c === comp.value)) {
        selectedComapnyList.push(comp);
      }
    });
  }

  const [selectedCompany, setSelectedCompany] = useState<MultiValue<any>>(selectedComapnyList);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const [nameError, setNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [phoneNumberError, setPhoneNumberError] = useState('');
  const [companyError, setCompanyError] = useState('');
  const [roleError, setRoleError] = useState('');

  const handleChangeName = (value) => {
    const nameValue = value.target.value;
    if (nameValue === '') {
      setName('');
      setNameError('');
      return;
    }
    setName(nameValue);
    setNameError('');
  };

  const handleChangeEmail = (it) => {
    if (!emailPattern.test(it.target.value)) {
      setEmailError(translate(LANGUAGE_KEYS.INVALID_EMAIL_ADDRESS));
    } else {
      setEmailError('');
    }
    setEmail(it.target.value);
  };

  const handleChangePhoneNumber = (it) => {
    const phonePattern = /^\d+$/;
    if (it.target.value === '') {
      setPhoneNumberError('');
      setPhoneNumber('');
      return;
    }
    if (!phonePattern.test(it.target.value)) {
      setPhoneNumberError(translate(LANGUAGE_KEYS.INVALID_PHONE_NUMBER));
    } else {
      setPhoneNumberError('');
    }
    setPhoneNumber(it.target.value);
  };

  const onChangeCompany = (newValue: OnChangeValue<any, true>) => {
    if (newValue.length <= 0) {
      setCompanyError(translate(LANGUAGE_KEYS.NO_SELECTED_COMPANY));
    } else {
      setCompanyError('');
    }
    setSelectedCompany(newValue);
    setIsDropdownOpen(false);
  };

  const addContact = () => {
    if (!isWorker) {
      if (selectedCompany === null || selectedCompany.length <= 0) {
        setCompanyError(translate(LANGUAGE_KEYS.NO_SELECTED_COMPANY));
        return;
      }
      const companyIdsMap = new Map<number, boolean>();
      selectedCompany.forEach((company) => companyIdsMap.set(company.value, false));
      if (itemModel !== null && itemModel.id !== null) {
        onAddContact({
          contactId: itemModel.id,
          email: email,
          phoneNumber: phoneNumber,
          name: name,
          type: CLIENT,
          companyIdsMap: Object.fromEntries(companyIdsMap),
        });
      } else {
        onAddContact({
          email: email,
          phoneNumber: phoneNumber,
          name: name,
          type: CLIENT,
          companyIdsMap: Object.fromEntries(companyIdsMap),
        });
      }
      closeDialog();
      return;
    }
    if (isWorker) {
      if (sendInviteCheckbox) {
        if (selectedRoleId === -1) {
          setRoleError(translate(LANGUAGE_KEYS.SELECT_ROLE_TEXT));
          return;
        }
        if (email === '') {
          setEmailError(translate(LANGUAGE_KEYS.NEED_EMAIL_FOR_INVITE));
          return;
        }
      }
      if (itemModel !== null && itemModel.id !== null) {
        onAddContact({
          contactId: itemModel.id,
          email: email,
          phoneNumber: phoneNumber,
          name: name,
          type: WORKER,
        });
        if (sendInviteCheckbox) {
          onSendInviteEmail({
            contactId: itemModel.id,
            email: email,
            role: selectedRoleId,
            sendInvitation: true,
            type: WORKER,
          });
        }
      } else {
        if (sendInviteCheckbox) {
          onSendInviteEmail({
            email: email,
            role: selectedRoleId,
            sendInvitation: true,
            type: WORKER,
            phoneNumber: phoneNumber,
            name: name,
          });
        } else {
          onAddContact({
            email: email,
            phoneNumber: phoneNumber,
            name: name,
            type: WORKER,
          });
        }
      }
      closeDialog();
      return;
    }
  };

  const handleOnCheckboxClick = (index: number, active: boolean) => {
    setSendInviteCheckbox(active);
  };

  const closeDialog = () => {
    if (!openSelect) {
      onClose();
    }
  };

  const customStyles = {
    menu: (provided: any) => {
      if (!controlRef.current) return provided;
      const controlRect = controlRef.current.getBoundingClientRect();
      return {
        ...provided,
        position: 'absolute',
        top: controlRect.bottom + 'px', // A Control elem alá helyezi a dropdown-t
        left: controlRect.left + 'px', // A Control elem bal oldalához igazítja
        width: controlRect.width + 'px', // A Control elem szélességéhez igazítja
        zIndex: 99999999, // Magas z-index, hogy a dropdown minden más fölött legyen
      };
    },
    menuPortal: (base: any) =>
      ({
        ...base,
        top: '0px', // A Control elem alá helyezi a dropdown-t
        left: '0px', // A Control elem bal oldalához igazítja
        width: '100px', // A Control elem szélességéhez igazítja
        zIndex: '999999999',
      } as CSSObjectWithLabel),
    control: (styles) =>
      ({
        ...styles,
        backgroundColor: 'white',
        border: '1px solid var(--dts_light_grey)',
        borderRadius: '3px',
        padding: '0px',
        minHeight: '36px',
        boxShadow: 'none',
        ':hover': {
          ...styles[':hover'],
          border: '1px solid var(--dts_light_grey)',
        },
        ':active': {
          ...styles[':active'],
          border: '1px solid var(--dts_light_grey)',
        },
      } as CSSObjectWithLabel),
    option: (styles) =>
      ({
        ...styles,
        backgroundColor: 'var(--dts_white)',
        color: 'var(--dts_dark)',
        fontFamily: 'Roboto, sans-serif',
        fontStyle: 'normal',
        fontWeight: '700',
        fontSize: '12px',
        cursor: 'default',
        padding: '8px',
        marginBottom: '4px',
        ':hover': {
          ...styles[':hover'],
          backgroundColor: 'var(--dts_withe_background)',
        },
      } as CSSObjectWithLabel),
    multiValue: (styles) =>
      ({
        ...styles,
        backgroundColor: 'var(--dts_light_grey)',
        padding: '6px 8px 6px 8px',
        borderRadius: '5px',
        margin: '0px',
      } as CSSObjectWithLabel),
    multiValueLabel: (styles) =>
      ({
        ...styles,
        padding: '0px',
        paddingLeft: '0px',
        fontFamily: 'Roboto, sans-serif',
        fontStyle: 'normal',
        fontWeight: '700',
        fontSize: '10px',
      } as CSSObjectWithLabel),
    multiValueRemove: (styles) =>
      ({
        ...styles,
        ':hover': {
          svg: {
            path: {
              fill: 'var(--dts_hover_blue)',
            },
          },
        },
        svg: {
          width: '7px',
          height: '7px',
          path: {
            fill: 'var(--dts_dark)',
          },
        },
        cursor: 'pointer',
      } as CSSObjectWithLabel),
  };

  const CustomControl = (props: any) => (
    <div ref={controlRef}>
      <components.Control {...props} />
    </div>
  );

  return (
    <BaseDialog
      isOpen={true}
      title={modalName}
      titleTextType={TextType.BODY_BOLD}
      onClose={closeDialog}
      containerStyle={{ width: '406px' }}
      header={true}
      isScroll={false}
    >
      <Container ref={modalRef}>
        <FirstSubContainer>
          {name !== '' ? (
            <>
              <Avatar
                fullName={name}
                size={{ outer: 120, inner: 118 }}
                textType={TextType.TITLE_BOLD_1}
              />
            </>
          ) : (
            <HeadIconStyled />
          )}
        </FirstSubContainer>
        <SecondSubContainer className='custom-scrollbar'>
          <LabelInputContainer>
            <Text type={TextType.CAPTION_BOLD}>{translate(LANGUAGE_KEYS.USER_NAME)}</Text>
            <InputComponent
              type={'text'}
              value={name}
              onChange={(it) => {
                handleChangeName(it);
              }}
              required
            ></InputComponent>
            <ErrorText type={TextType.CAPTION_REGULAR}>{nameError}</ErrorText>
          </LabelInputContainer>
          <LabelInputContainer>
            <Text type={TextType.CAPTION_BOLD}>{translate(LANGUAGE_KEYS.EMAIL)}</Text>
            <InputComponent
              type={'email'}
              value={email}
              onChange={(it) => {
                handleChangeEmail(it);
              }}
              required
            ></InputComponent>
            <ErrorText type={TextType.CAPTION_REGULAR}>{emailError}</ErrorText>
          </LabelInputContainer>
          <LabelInputContainer>
            <Text type={TextType.CAPTION_BOLD}>{translate(LANGUAGE_KEYS.PHONE)}</Text>
            <InputComponent
              type={'text'}
              value={phoneNumber}
              onChange={(it) => {
                handleChangePhoneNumber(it);
              }}
              required
            ></InputComponent>
            <ErrorText type={TextType.CAPTION_REGULAR}>{phoneNumberError}</ErrorText>
          </LabelInputContainer>
          {isWorker &&
            (itemModel === null || (itemModel !== null && itemModel.roleId === null)) && (
              <LabelInputContainer className='checkbox-container'>
                <Checkbox
                  checkboxId={0}
                  onClick={handleOnCheckboxClick}
                  active={sendInviteCheckbox}
                  widthBox={16}
                  heightBox={16}
                  disabled={true}
                />
                <Text type={TextType.ROBOTO_400_10_14}>
                  {translate(LANGUAGE_KEYS.SEND_EMAIL_INVITE_TO_JOIN_DENTECH)}
                </Text>
              </LabelInputContainer>
            )}
          {isWorker &&
            (sendInviteCheckbox ||
              (itemModel !== null &&
                itemModel.roleId !== null &&
                itemModel.roleId !== null &&
                itemModel.roleId !== UserRoles.LABOR_OWNER)) && (
              <>
                <LabelInputContainer className={`${isWorker ? 'visible' : 'hidden'}`}>
                  <Text type={TextType.CAPTION_BOLD}>{translate(LANGUAGE_KEYS.ROLE)}</Text>
                  <Selected
                    ref={roleSelectRef}
                    onClick={() => {
                      setIsOpen(!isOpen);
                    }}
                  >
                    <Text type={TextType.CAPTION_BOLD} ellipsis={true}>
                      {selectedDropdownValue}
                    </Text>
                    <DropdownIconStyled className={`${isOpen && 'open'}`} />
                  </Selected>
                  <DynamicDropdown
                    isOpen={isOpen}
                    parentRef={roleSelectRef}
                    modalRef={modalRef}
                    hasSuccessIcon={true}
                    values={roles.map((role) => {
                      return {
                        key: role.id,
                        label: role.name,
                        selected: role.id === selectedRoleId,
                      };
                    })}
                    textType={TextType.CAPTION_BOLD}
                    onChangeName={(name) => {
                      setSelectedDropdownValue(name);
                    }}
                    onSelect={(changedValueKey) => {
                      setRoleError('');
                      if (itemModel !== null && itemModel.roleId !== changedValueKey) {
                        setSendInviteCheckbox(true);
                      }
                      setSelectedRoleId(changedValueKey);
                    }}
                    onChangeOpenState={(state: boolean) => {
                      setIsOpen(state);
                    }}
                    maxHeightOptions={140}
                    disabledTooltip={true}
                  />
                  <ErrorText type={TextType.CAPTION_REGULAR}>{roleError}</ErrorText>
                </LabelInputContainer>
              </>
            )}
          <LabelInputContainer className={`${!isWorker ? 'visible' : 'hidden'}`}>
            <Text type={TextType.CAPTION_BOLD}>{translate(LANGUAGE_KEYS.COMPANY_NAME)}</Text>
            <CustomSelectStyle
              value={selectedCompany}
              options={companies}
              className={`'multi-select' ${companies.length >= 5 && 'top-option'}`}
              classNamePrefix='select'
              placeholder={translate(LANGUAGE_KEYS.SEARCH_FOR_COMPANY)}
              closeMenuOnSelect={true}
              menuPlacement={'auto'}
              maxMenuHeight={170}
              components={{
                DropdownIndicator,
                MultiValueRemove,
                IndicatorSeparator: () => null,
                ClearIndicator: () => null,
                NoOptionsMessage,
                Control: CustomControl, // A Control komponens felülírása
              }}
              onChange={onChangeCompany}
              onMenuOpen={() => {
                setIsDropdownOpen(true);
                setOpenSelect(true);
              }}
              onMenuClose={() => {
                setIsDropdownOpen(false);
                setOpenSelect(false);
              }}
              onBlur={() => {
                setTimeout(() => {
                  setOpenSelect(false);
                }, 200);
              }}
              isMulti
              menuPortalTarget={document.body} // A dropdown áthelyezése a body-ba
              styles={customStyles}
              menuIsOpen={isDropdownOpen}
            />
            <ErrorText type={TextType.CAPTION_REGULAR}>{companyError}</ErrorText>
          </LabelInputContainer>
        </SecondSubContainer>
        <ThirdSubContainer>
          <Button textType={TextType.CAPTION_BOLD} onClick={closeDialog} secondary='secondary'>
            {translate(LANGUAGE_KEYS.CANCEL)}
          </Button>
          <Button textType={TextType.CAPTION_BOLD} onClick={addContact}>
            {itemModel !== null && !sendInviteCheckbox && translate(LANGUAGE_KEYS.EDIT)}
            {itemModel !== null &&
              (itemModel.roleId === null || itemModel.roleId === null) &&
              sendInviteCheckbox &&
              translate(LANGUAGE_KEYS.EDIT_SEND)}
            {itemModel !== null &&
              itemModel.roleId !== null &&
              itemModel.roleId !== null &&
              sendInviteCheckbox &&
              translate(LANGUAGE_KEYS.SAVE)}
            {itemModel === null && !sendInviteCheckbox && translate(LANGUAGE_KEYS.CREATE)}
            {itemModel === null && sendInviteCheckbox && translate(LANGUAGE_KEYS.CREATE_SEND)}
          </Button>
        </ThirdSubContainer>
      </Container>
    </BaseDialog>
  );
};

const Selected = styled.div`
  position: relative;
  background-color: var(--dts_white);
  padding: 8.5px 8px;
  border: 1px solid var(--dts_light_grey);
  border-radius: 3px;
  cursor: pointer;
  user-select: none;
  &.disabled {
    cursor: not-allowed;
  }
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
`;

const DropdownIconStyled = styled(DropdownIcon)`
  fill: var(--dts_dark);
  flex-shrink: 0;
  &.open {
    transform: rotate(180deg);
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: calc(100% - 48px);
  max-height: calc(100% - 48px);
`;

const FirstSubContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  gap: 24px;
`;

const HeadIconStyled = styled(HeadIcon)`
  height: 120px;
  width: 120px;

  path {
    fill: var(--dts_dark_grey);
  }
`;

const SecondSubContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 8px;
  flex-direction: column;
  overflow-y: auto;
  height: calc(100% - 5px);
  max-height: calc(100% - 50px);
`;

const LabelInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100%;
  gap: 2px;
  width: 100%;

  &.visible {
    opacity: 1;
    transition: all 0.2s;
    visibility: visible;
  }

  &.hidden {
    display: none;
    opacity: 0;
    transition: all 0.2s;
    visibility: hidden;
  }
  &.checkbox-container {
    flex-direction: row;
    gap: 8px;
    margin-bottom: 16px;
  }
  .top-option {
    .select__menu {
      top: unset;
      bottom: 40px;
    }
  }
`;

const InputComponent = styled.input`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  padding: 8px;
  border: 1px solid var(--dts_light_grey);
  border-radius: 3px;
  color: var(--dts_dark);
  box-shadow: none;
  outline: none;
`;

const ErrorText = styled(Text)`
  width: 100%;
  color: red;
  min-height: 16px;
`;

const CustomSelectStyle = styled(Select)`
  .select__menu-list::-webkit-scrollbar {
    width: 8px;
    height: 0;
  }

  .select__menu-list::-webkit-scrollbar-thumb {
    width: 8px;
    background: linear-gradient(
      180deg,
      rgba(24, 160, 251, 0.53) 22.5%,
      rgba(197, 50, 182, 0.12) 91.25%
    );
    border-radius: 8px;
  }

  .select__value-container {
    padding: 8px;
    gap: 4px;

    &.select__value-container--has-value {
      padding: 5px 4px 5px 4px;
    }

    .select__placeholder {
      margin: 0;
      font-family: Roboto, sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: var(--dts_dark);
    }

    .select__input-container {
      margin: 0;
      padding: 0;
      max-height: 16px;
    }
  }

  .select__indicators {
    cursor: pointer;
    padding: 8px;

    &:hover {
      .select__indicator {
        svg {
          path {
            fill: var(--dts_hover_blue);
          }
        }
      }
    }

    .select__indicator {
      padding: 0;

      svg {
        width: 9px;
        height: 9px;

        path {
          fill: var(--dts_dark);
        }
      }
    }
  }

  .select__control {
    .select__input {
      font-family: Roboto, sans-serif !important;
      font-style: normal !important;
      font-weight: 400 !important;
      font-size: 12px !important;
      line-height: 16px !important;
    }
  }

  .select__menu {
    margin: 0;
    padding: 24px;
    box-shadow: -1px 2px 11px 0 #00000024;
  }
`;

const ThirdSubContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 16px;
`;

export default AddContactDialog;
